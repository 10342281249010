<template>

  <BlockUI :blocked="loading>0">

    <div class="p-grid">

      <div class="p-col-12 p-md-6">
        <h3><i class="pi pi-home" style="font-size: 1.6rem"></i> Cadastro de Imóveis</h3>
      </div>

      <div class="p-col-12 p-md-6">
        <Button
          id="buttonGravar"
          label="Gravar"
          icon="pi pi-check"
          style="float: right"
          class="p-mr-2 p-mb-3 p-button-lg p-button-success"
          @click="criar_alterar"
          v-if="$auth.userCan('IMOVEIS_CADASTRAR')"
        ></Button>
      </div>

    </div>

    <div class="p-grid">
        <div class="p-col-12">
            <div class="card p-fluid">
                <div class="p-grid">

                    <div class="p-field p-md-2">
                      <label for="tipo">Tipo</label>
                      <Dropdown
                        id="tipo"
                        v-model="tipo_selecionado"
                        :options="tipos"
                        optionLabel="nome"
                        optionValue="id"
                        placeholder="Selecione"
                        :class="(fieldsError.some(v => v === 'tipo'))?'p-invalid':''"
                      />
                    </div>
                    <div class="p-field p-md-2">
                        <label for="id_proprio">Identificação</label>
                        <InputText
                        id="id_proprio"
                        type="text"
                        v-model="id_proprio"
                        :class="(fieldsError.some(v => v === 'id_proprio'))?'p-invalid':''"
                        maxlength="50"
                        />
                    </div>
                    <div class="p-field p-md-8">
                        <label for="nome">Nome</label>
                        <InputText
                        id="nome"
                        type="text"
                        v-model="nome"
                        :class="(fieldsError.some(v => v === 'nome'))?'p-invalid':''"
                        maxlength="300"
                        />
                    </div>
                </div>

                <!-- Endereço -->
                <div class="p-grid">
                    <div class="p-field p-md-2">
                        <label for="cep">CEP</label>
                        <InputMask
                        id="cep"
                        type="text"
                        v-model="cep"
                        :class="(fieldsError.some(v => v === 'cep'))?'p-invalid':''"
                        mask="99.999-999"
                        :unmask="true"
                        @focus="cepInvalido = false"
                        />
                        <InlineMessage v-if="cepInvalido">CEP Inválido</InlineMessage>
                    </div>

                    <div class="p-field p-md-5">
                        <label for="logradouro">Endereço</label>
                        <InputText
                        id="logradouro"
                        type="text"
                        v-model="logradouro"
                        :class="(fieldsError.some(v => v === 'logradouro'))?'p-invalid':''"
                        maxlength="300"
                        />
                    </div>

                    <div class="p-field p-md-1">
                        <label for="numero">Número</label>
                        <InputText
                        id="numero"
                        type="text"
                        v-model="numero" :class="(fieldsError.some(v => v === 'numero'))?'p-invalid':''"
                        maxlength="10"
                        />
                    </div>

                    <div class="p-field p-md-4">
                        <label for="complemento">Complemento</label>
                        <InputText
                        id="complemento"
                        type="text"
                        v-model="complemento" :class="(fieldsError.some(v => v === 'complemento'))?'p-invalid':''"
                        maxlength="150"
                        />
                    </div>

                    <div class="p-field p-md-4">
                        <label for="bairro">Bairro</label>
                        <InputText
                        id="bairro"
                        type="text"
                        v-model="bairro" :class="(fieldsError.some(v => v === 'bairro'))?'p-invalid':''"
                        maxlength="150"
                        />
                    </div>

                    <div class="p-field p-md-4">
                        <label for="cidade">Cidade</label>
                        <InputText
                        id="cidade"
                        type="text"
                        v-model="cidade" :class="(fieldsError.some(v => v === 'cidade'))?'p-invalid':''"
                        maxlength="100"
                        />
                    </div>

                    <div class="p-field p-md-1">
                        <label for="uf">UF</label>
                        <InputText
                        id="uf"
                        type="text"
                        v-model="uf" :class="(fieldsError.some(v => v === 'uf'))?'p-invalid':''"
                        maxlength="20"
                        />
                    </div>

                    <div class="p-field p-md-3" v-if="this.$auth.currentUser.imobiliaria == null && imobiliaria_selecionada == null">
                      <label for="imobiliaria">Imobiliária</label>
                      <Dropdown
                        id="imobiliaria"
                        v-model="imobiliaria_selecionada"
                        :options="imobiliarias"
                        :showClear="true"
                        optionLabel="nome"
                        optionValue="id"
                        placeholder="Selecione"
                        :class="(fieldsError.some(v => v === 'imobiliaria'))?'p-invalid':''"
                      />
                    </div>

                </div>
            </div>
            
        </div>
    </div>

    <div v-show="processos.length > 0" class="p-mt-3">

      <h5 class="p-mb-5"><i class="pi pi-file" style="font-size: 1.4rem"></i> Cadastros realizados para este imóvel</h5>
      <div class="p-grid">
        <div class="p-col-12">

          <DataTable sortMode="single" :value="processos" selectionMode="single" v-model:selection="processo">
            <Column field="id" header="Negócio" class="col-reduzida"></Column>
            <Column field="locatario_item.nome" header="Locatário"></Column>
            <Column field="data" header="Data Cadastro">
              <template #body="slotProps">
                {{ $utils.formatDateTimeToBr(slotProps.data.data) }}
              </template>
            </Column>
            <Column field="data" header="Data Atualização">
              <template #body="slotProps">
                {{ $utils.formatDateTimeToBr(slotProps.data.data_atualizacao) }}
              </template>
            </Column>
            <Column field="data_expiracao" header="Expira Em">
              <template #body="slotProps">
                <span v-if="$utils.daysFromNow(slotProps.data.data_expiracao) > 0">{{ $utils.daysFromNow(slotProps.data.data_expiracao) }} dia(s) </span>
                <span v-else>-</span>
              </template>
            </Column>

            <Column field="status" header="Status">
              <template #body="slotProps">
                <Tag 
                  :value="status[slotProps.data.status].label"
                  :icon="status[slotProps.data.status].icon" 
                  :severity="status[slotProps.data.status].severity"
                  :class="(typeof status[slotProps.data.status].class != 'undefined')?status[slotProps.data.status].class:''"
                >
                </Tag>
              </template>
            </Column>
            <template #empty>
                Nenhum Cadastro encontrado para este imóvel.
            </template>
          </DataTable>
        </div>
      </div>
    </div>

    <div class="center-loading" v-if="loading>0">
        <ProgressSpinner />
    </div>

  </BlockUI>

  <ConfirmPopup></ConfirmPopup>
  <Toast position="top-right" />
</template>

<script>
import axios from "axios";

export default {

  props: [
    "imobiliaria",
    "imovel_edit"
  ],

  emits: [
    "save"
  ],

  data() {
    return {
      // 1. Controle de apresentação
      loading: 2,

      // 1.1. Validadores
      cepInvalido: false,
      fieldsError: [],

      // 3.1. Informações Gerais

      id_proprio: "",
      nome: "",

      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      uf: "",

      imobiliarias: [],
      imobiliaria_selecionada: null,

      tipos: [{
        id: "APARTAMENTO",
        nome: "Apartamento",
      },{
        id: "CASA",
        nome: "Casa",
      }],
      tipo_selecionado: null,

      // 4. Dado selecionado para edição

      imovel : null,

      // 5. Processos

      processo: null,
      processos: [],
      status: {
        
        'NOVO' : {
          label: 'EM ANÁLISE',
          severity: "info",
          icon: "pi pi-clock",
          class: 'tag-novo',
        },
        'PROCESSANDO' : {
          label: 'PROCESSANDO',
          severity: "warning",
          icon: "pi pi-clock",
        },
        'APROVADO' : {
          label: 'CADASTRO APROVADO',
          severity: "success",
          icon: "pi pi-exclamation-triangle",
        },
        'RECUSADO' : {
          label: 'CADASTRO RECUSADO',
          severity: "danger",
          icon: "pi pi-times",
        },
        'EM_CONTRATACAO' : {
          label: 'EM CONTRATAÇÃO',
          severity: "warning",
          icon: "pi pi-clock",
        },
        'AGUARDANDO_CONTRATO': {
          label: 'AGUARDANDO CONTRATO',
          severity: "warning",
          icon: "pi pi-file",
          class: 'tag-contrato',
        },
        'SEGURO_REGISTRADO': {
          label: 'APÓLICE EMITIDA',
          severity: "info",
          icon: "pi pi-check",
        },
        'REGISTRO_NEGADO' : {
          label: 'EMISSÃO NEGADA',
          severity: "danger",
          icon: "pi pi-times",
        },
        'APOLICE_CANCELADA' : {
          label: 'APÓLICE CANCELADA',
          severity: "danger",
          icon: "pi pi-times",
        },
        'EXPIRADO' : {
          label: 'CADASTRO EXPIRADO',
          severity: "danger",
          icon: "pi pi-exclamation-triangle",
        },
        'EXCLUIDO' : {
          label: 'EXCLUÍDO',
          severity: "danger",
          icon: "pi pi-trash",
        },

      },
      
    };
  },

  mounted() {

    if(this.imobiliaria != "undefined" && this.imobiliaria != null) {
      this.imobiliaria_selecionada = this.imobiliaria;
    }

    const self = this;

    this.$api
      .get("/imobiliarias/?fields=nome")
      .then(function (response) {
        const dados = response.data;
        if (dados.success) { 
          self.imobiliarias = dados.data;
        }

        self.loading--;

      });

    this.carregarObjetoRota();
  },

  watch: {

    $route() {
      if(this.$route.name == "imovel")
        this.carregarObjetoRota();
    },

    processo(val) {

      if(val) {
        this.$router.push({ path: `/processo/${val.id}` });
      }

    },

    cep: function (val) {

      if(val.length == 8) {
        
        const self = this;

        self.loading++;

        axios
        .get("https://brasilapi.com.br/api/cep/v1/" + val)
        .then(function (response) {
            //Sucesso
            if (!self.uf) self.uf = response.data.state;
            if (!self.cidade) self.cidade = response.data.city;
            if (!self.bairro) self.bairro = response.data.neighborhood;
            if (!self.logradouro) self.logradouro = response.data.street;
            self.loading--;
        })
        .catch(function (error) {
            //Erro
            if (error.response.data.name == "CepPromiseError")
                self.cepInvalido = true;
            self.loading--;
        });

      }
    },

    imovel: function (val) {
      
      this.limparFormulario();

      if (val != null) {

        // 3. Dados de formulários
        this.id_proprio = val.id_proprio;
        this.nome = val.nome;
        this.cep = val.cep;
        this.logradouro = val.logradouro;
        this.numero = val.numero;
        this.complemento = val.complemento;
        this.bairro = val.bairro;
        this.cidade = val.cidade;
        this.uf = val.uf;
        this.imobiliaria_selecionada = val.imobiliaria;
        this.tipo_selecionado = val.tipo;

      }
    },

  },

  methods: {

    // // // Métodos padrões para este modelo de tela // // //

    carregarObjetoRota() {
      const self = this;

      let id_edit = ""
      if(typeof this.imovel_edit == "string" && this.imovel_edit != "" || typeof this.imovel_edit == "number")
        id_edit = this.imovel_edit.toString();
      else if(typeof this.imovel_edit == "object" && this.imovel_edit != null && typeof this.imovel_edit.id != "undefined")
        id_edit = this.imovel_edit.id.toString();
      else if(this.$route.name == "imovel" && typeof this.$route.params.id == "string")
        id_edit = this.$route.params.id;

      if ( id_edit == "" ) {
        this.limparFormulario();
        self.loading--;
        return;
      }

      let bloqueio = setInterval(() => {

        if(self.loading == 1) {

          this.$api
            .get("/imoveis/" + id_edit)
            .then(function (response) {
              const dados = response.data;
              if (dados.success) { 
                self.imovel = dados.data;
                self.loading = false;

                if(self.$route.name == "imovel")
                  self.carregaProcessos(self.imovel.id);

              } else {
                alert(dados.message);
                self.$router.push({ path: `/imoveis` });
              }

              self.loading--;

              clearInterval(bloqueio);

            });

        }

      },500);

    },

    carregaProcessos(imovel) {
      const self = this;

      let api_get = '?fields=id,data,data_atualizacao,data_expiracao,status&relationships=imovel_item:id_proprio;locatario_item:nome';
      api_get += '&filters={%22imovel%22:[%22' + imovel +'%22]}';

      this.$api.get('/processos' + api_get).then(function (response) {

        const dados = response.data;

        if(dados.success) {
          self.processos = dados.data.data;
        }
        
      });

    },

    limparFormulario() {
      //Limpa as variáveis
    
      // 1.1. Validadores
      this.cepInvalido = false;

      // 3. Dados de formulários    

      this.id_proprio = "";
      this.nome = "";
      this.cep = "";
      this.logradouro = "";
      this.numero = "";
      this.complemento = "";
      this.bairro = "";
      this.cidade = "";
      this.uf = "";
      this.imobiliaria_selecionada = null;
      this.tipo_selecionado = null;

    },

    // // // AÇÕES DO USUÁRIO // // //

    criar_alterar() {

      let id_edit = ""
      if(typeof this.imovel_edit == "string" && this.imovel_edit != "" || typeof this.imovel_edit == "number")
        id_edit = this.imovel_edit.toString();
      else if(typeof this.imovel_edit == "object" && this.imovel_edit != null && typeof this.imovel_edit.id != "undefined")
        id_edit = this.imovel_edit.id.toString();
      else if(this.$route.name == "imovel" && typeof this.$route.params.id == "string")
        id_edit = this.$route.params.id;
      
      const self = this;
      const criar = ( this.imovel == null || id_edit == "" );

      this.fieldsError = [];

      let dados = {
        "id_proprio": this.id_proprio,
        "nome": this.nome,
        "logradouro": this.logradouro,
        "numero": this.numero,
        "complemento": this.complemento,
        "bairro": this.bairro,
        "cidade": this.cidade,
        "uf": this.uf,
        "cep": this.cep.replace(/[^0-9]/g, ""),
        "imobiliaria": this.imobiliaria_selecionada,
        "tipo": this.tipo_selecionado,
      };

      if (!criar) {
        dados = Object.assign(this.$utils.getStdObject(this.imovel), this.$utils.getStdObject(dados));
        dados["_method"] = "PUT";
      }

      this.$api
        .post(
          "/imoveis" + (!criar ? "/" + id_edit : ""),
          dados
        )
        .then(function (response) {

          self.$toast.add({
            severity: (response.data.success)?"success":"error",
            summary: (response.data.success)?"Sucesso!":"ERRO!",
            detail: response.data.message,
            life: (response.data.success)?3000:5000,
          });

          self.limparFormulario();

          if(response.data.success)
            self.$emit("save", response.data.data);
          else
            self.$emit("save", null);

        })
        .catch(function (erro) {

          if (typeof erro.response != "undefined") {

            const response = erro.response;

            if(typeof response.data.fields != "undefined") {

              const dicionario = {             
              };

              for(const f of response.data.fields) {

                if(typeof dicionario[f] != "undefined") {
                  self.fieldsError.push(dicionario[f]);
                } else {
                  self.fieldsError.push(f);
                }

              }

            }

            self.$toast.add({
              severity: "error",
              summary: "ERRO!",
              detail: response.data.message,
              life: 5000,
            });

          }

        });
    }

  },
};
</script>

<style scoped>
</style>