<template>

  <div class="selecao-detalhes" v-if="imovel_view != null">
    <div class="p-grid">
      <div class="p-field p-md-2">
        <label for="id_proprio">Identificação:</label>
        <span>{{ imovel_view.id_proprio }}</span>
      </div>
      <div class="p-field p-md-10">
        <label for="nome">Nome:</label>
        <span>{{ imovel_view.nome }}</span>
      </div>
    </div>

    <!-- Endereço -->
    <div class="p-grid">
      <div class="p-field p-md-2">
        <label for="cep">CEP:</label>
        <span>{{ imovel_view.cep }}</span>
      </div>

      <div class="p-field p-md-5">
        <label for="logradouro">Endereço:</label>
        <span>{{ imovel_view.logradouro }}</span>
      </div>

      <div class="p-field p-md-1">
        <label for="numero">Núm:</label>
        <span>{{ imovel_view.numero }}</span>
      </div>

      <div class="p-field p-md-4">
        <label for="complemento">Complemento:</label>
        <span>{{ imovel_view.complemento }}</span>
      </div>

      <div class="p-field p-md-4">
        <label for="bairro">Bairro:</label>
        <span>{{ imovel_view.bairro }}</span>
      </div>

      <div class="p-field p-md-4">
        <label for="cidade">Cidade:</label>
        <span>{{ imovel_view.cidade }}</span>
      </div>

      <div class="p-field p-md-1">
        <label for="uf">UF</label>
        <span>{{ imovel_view.uf }}</span>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  props: [
    "imovel"
  ],
  emits: [
  ],
  data() {
    return {
      // 1. Controle de apresentação
      loading: 1,
      // 2. Dados de apresentação
      imovel_view: null
    }
  },
  mounted() {

    if(typeof this.imovel == "object" && this.imovel != null) {
      this.imovel_view = this.imovel;
      this.loading = 0;
    } else if(typeof this.imovel != "undefined") {
      this.carregarObjeto();
    }

  },
  methods: {

    async carregarObjeto() {

      const response = await this.$api.get("/imoveis/" + this.imovel);
      const dados = response.data;
      if (dados.success) {
        this.imovel_view = dados.data;
      }

      this.loading--;

    }

  }
}
</script>
<style lang="scss" scoped>

.selecao-detalhes {
  margin-top: 1px;
}

.selecao-detalhes .p-field {
  border: 1px solid #ccc;
  margin: 0;
  padding: 0.3rem 0.4rem;
  margin-top: -1px;
}

.selecao-detalhes .p-field span {
  display: block;
}

.selecao-detalhes .p-field label {
  font-weight: 500;
  margin-bottom: 4px;
}

.selecao-detalhes .p-grid {
  margin: 0;
}

.selecao-detalhes th, .selecao-detalhes td {
  background: none !important;
  padding: 0.5rem !important;
}

</style>